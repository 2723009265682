.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.iframe-padding-left {
  padding-left: calc(calc(100vw - 816px) / 2);
}

.hero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./assets/images/hero.jpg");
  width: 100%;
  height: 92vh;
  display: flex;
  flex-direction: column;
  background-position: bottom;
  align-self: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  align-items: center;
  justify-content: center;
}

.doc-content {
  padding: 0 !important;
  max-width: 1200px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 700) {
  .iframe-padding-left {
    padding: default;
  }
}

@media only screen and (max-width: 700px) {
  .hero {
    height: 70vh;
  }
}

/***************** Animation for landing page ******************/

@keyframes hideshow {
  0% {
    opacity: 0.33;
    transform: translateX(-3px);
  }
  33% {
    opacity: 1;
    transform: translateX(0px);
  }
  50% {
    opacity: 0;
    transform: translateX(-3px);
  }
  66% {
    opacity: 0;
    transform: translateX(-6px);
  }
  100% {
    opacity: 0.33;
    transform: translateX(-3px);
  }
}

@keyframes hideshowArea {
  0% {
    opacity: 0;
    transform: translateX(-5px);
  }
  33% {
    opacity: 1;
    transform: translateX(0px);
  }
  50% {
    opacity: 0;
    transform: translateX(-3px);
  }
  66% {
    opacity: 0;
    transform: translateX(-6px);
  }
  100% {
    opacity: 0;
    transform: translateX(-3px);
  }
}

#bL1 {
  animation: rotate 10s ease infinite;
}

#bL2 {
  animation: rotate 10s ease infinite;
}

#bL3 {
  animation: highSlide 10s ease infinite;
}

#bL4 {
  animation: midSlide 10s ease infinite;
}

#bL5 {
  animation: lowSlide 10s ease infinite;
}

#aL1,
#aL2,
#aL3,
#aL4,
#aP1,
#aP2,
#aP3 {
  animation: hideshow 10s ease infinite;
}

#vP1,
#mP1 {
  animation: hideshowArea 10s 0.2s ease infinite;
}

#vP2,
#mP2 {
  animation: hideshowArea 10s 0.4s ease infinite;
}

#vP3,
#mP3 {
  animation: hideshowArea 10s 0.6s ease infinite;
}

@keyframes lowSlide {
  0% {
    transform: translateX(0px);
  }
  33% {
    transform: translateX(1px);
  }
  50% {
    transform: translateX(0px);
  }
  66% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes midSlide {
  0% {
    transform: translateX(0px);
  }
  33% {
    transform: translateX(2px);
  }
  50% {
    transform: translateX(0px);
  }
  66% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes highSlide {
  0% {
    transform: translateX(0px);
  }
  33% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(0px);
  }
  66% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 100%;
  }
  33% {
    transform: rotate(2deg);
    transform-origin: 50% 100%;
  }
  50% {
    transform: rotate(0deg);
    transform-origin: 50% 100%;
  }
  66% {
    transform: rotate(0deg);
    transform-origin: 50% 100%;
  }
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 100%;
  }
}
/***************** CSS for problem generator project ******************/
/* .question-categories {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
  margin: auto;
}
.question-category {
  border: 2px solid black;
  background-color: lightgrey;
  border-radius: 5px;
  height: 200px;
  width: 300px;
  margin: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.question-container {
  border: 2px solid grey;
  border-radius: 5px;
  margin: 5px;
  width: 60vw;
  display: flex;
  gap: 10px;
  padding: 10px;
} */
/* .question {
} */
/* .question {
  text-align: start;
}
.choices {
  padding: 10px;
}
.choices > li {
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px;
  margin: 5px;
} */
